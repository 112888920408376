import { useStaticQuery, graphql } from 'gatsby'

export default function useReleaseNotes() {
	const { allMarkdownRemark } = useStaticQuery<GatsbyTypes.SiteMarkdownQuery>(
		graphql`
			query SiteMarkdown {
				allMarkdownRemark(filter: {fileAbsolutePath: {glob: "**/*/release-notes/**/*"}}) {
					nodes {
						frontmatter {
							version
							date
						}
						html
					}
				}
			}
		`
	)
	return allMarkdownRemark.nodes
		.filter(Boolean)
		.sort((a, b) => (
			(a.frontmatter?.version ?? '') <
			(b.frontmatter?.version ?? '') ? 1 : -1
		))
}
