import React from 'react'
import Layout from './Layout'
import useReleaseNotes from './Hooks/useReleaseNotes'

export default function ReleaseNotes(props: { pageContext }) {
	const releaseNotes = useReleaseNotes()

	return (
		<Layout pageContext={props.pageContext}>
			<>
				<h1>Release Notes</h1>
				{
					releaseNotes?.map((page, index) => {
						const prettyDate = new Date(page.frontmatter?.date ?? '')
							.toLocaleDateString('en', { month: 'long', day: 'numeric', year: 'numeric', timeZone: 'GMT' })
						return (
							<article key={index}>
								<h2>Harbor Version {page.frontmatter?.version}</h2>
								<time dateTime={page.frontmatter?.date}>{prettyDate}</time>
								<div dangerouslySetInnerHTML={{ __html: page.html ?? '' }} />
							</article>
						)
					})
				}
			</>
		</Layout>
	)
}
